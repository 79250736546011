<style scoped lang='scss'>
.OS_sol_title{
  display: inline-block;
  font-size: 24px;
  margin-bottom: 24px;
  padding-bottom: 6px;
  border-bottom: 1px solid #000;
}
.OS_sol_title2{
  margin-bottom: 40px;
  font-size: 18px;
}
#OS_solutions{
  .main_introduce{
    width: 920px;
    margin: 0 auto;
    padding: 0 10px 0px;
  }
  .colorTit{
    color: $color;
    margin-bottom: 20px;
  }
  .sol_os_xx {
    margin-bottom: 60px;
    img{
      margin-top: 50px;
      margin-bottom: 30px;
      width: 100%;
    }
    .sol_os_xx_tit{
      font-size: 18px;
      line-height: 1.8rem;
      margin: 1rem auto;
      span{
        color: #f85780;
        text-decoration: underline;
      }
    }
  }
  p{ font-size: 16px; margin-bottom: 1.5rem; line-height: 1.6em; padding-left: 30px; }
  .weight{ margin-bottom: 20px; font-size: 18px; }
  
  .VividOS{
    margin-bottom: 40px;
    .VividOS_ul li{
      margin-bottom: 20px;
      font-size: 16px;
      padding-left: 20px;
      position: relative;
    }
    .VividOS_ul li span{
      width: 12px;
      height: 12px;
      background-color: #54bbff;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: calc(50% - 6px);
    }
    img{
      width: 510px;
      margin: 0;
    }
  }
  .OS_solutions_list1{
    margin-bottom: 80px;
  }
  .tiyan{
    margin-bottom: 60px;
    .tiyan_ms{
      font-size: 16px;
      line-height: 1.6rem;
      margin-bottom: 1em;
    }
    img{
      width: 667px;
      margin-bottom: 20px;
    }
  }
  .OS_sol_sd{
    margin-bottom: 60px;
    .OS_sol_sd_ms{
      font-size: 16px;
      line-height: 1.6em;
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }
  .OS_sol_sx{
    img{
      width: 640px;
      margin-bottom: 20px;
    }
    p{
      padding-left: 0;
    }
  }
}

</style>

<template>
  <div id="OS_solutions">
    <div class="width rullMain">
      <div class="main_introduce">
        <div class="title textCenter weight">嵌入式操作系统</div>
        <div class="colorTit">是我司独自开发的（一部分功能以Linux为基础）控制、管理嵌入式系统的平台。通过Layer结构，可管理产品开发成果的平台，在缩短开发周期的同时提高了产品质量。我司的VividOS主要面向游戏机、玩具、家电等产品领域进行了优化。</div>
        <img class="center-block" src="@/assets/images/business/1/sol_os_main.png" alt="">
        <div class="sol_os_xx">
          <img class="center-block" src="@/assets/images/business/1/sol_os_01-1.jpg" alt="">
          <div class="sol_os_xx_tit textCenter">
            <div>吸取了RTOS和Linux双方的优点、</div>
            <div>实现了<span>“小巧、高性能”</span>、<span>“低成本、高品质的用户体验”</span></div>
            <div>并朝着成为电子产品的最优化平台努力。</div>
          </div>  
        </div>
        <div class="acea-row row-between VividOS">
          <div>
            <div class="OS_sol_title">VividOS的特征</div>
            <ul class="VividOS_ul">
              <li><span></span>低成本</li>
              <li><span></span>舒适的用户体验</li>
              <li><span></span>可实现敏捷开发</li>
              <li><span></span>稳定性、安全性、可靠性高</li>
            </ul>
          </div>
          <img src="@/assets/images/business/1/VividOS01-768x428.png" alt="">
        </div>
        <div class="OS_solutions_list1">
          <div class="weight">低成本</div>
          <p>
            通过硬件等级的OS调整，即使是低规格的处理器也能充分发挥性能。通过抑制CPU性能和内存使用量，扩大可使用处理器的选择范围，可以得到性比价较高的产品。
          </p>
          <div class="weight">舒适的用户体验</div>
          <p>
            通过使用vividOS，将各阶段的处理优化到极限，实现高速启动、高速响应。
            因使用独自的GPU/Display驱动程序，用户在使用产品时无卡顿，给用户带来更舒适的体验。
          </p>
          <div class="weight">可实现敏捷开发</div>
          <p>
            VividOS管理了各功能模块，简化了繁杂的工作。
            应用开发环境的构筑（提供开发SDK套装/实机用系统描述/开发用程序库等）、故障发生时,容易区分应用程序和OS的原因，产品开发的难易度比以前低，应用程序开发者可以迅速着手产品的开发。  
          </p>
          <div class="weight">稳定性、安全性、可靠性高</div>
          <p>
            VividOS过去被用于超过百万台的电子产品中，也有大量生产的实绩。
            还管理了搭载的OSS的状况，可以在社会上推出稳定性、安全性、可靠性高的高品质产品。  
          </p>
        </div>
        <div class="tiyan">
          <div class="OS_sol_title">关于舒适的用户体验</div>
          <div class="OS_sol_title2">聚焦目标领域，强化功能，达到性能的极致</div>
          <img src="@/assets/images/business/1/sol_os_02.jpg" alt="">
          <img src="@/assets/images/business/1/sol_os_03.jpg" alt="">
          <div class="tiyan_ms">
            <div>在Andorid平台，为了实现高通用性、多种多样的功能，构成庞大、复杂。</div>
            <div>虽然获得了高功能，但是在所有处理中都需要进行各种中间处理。</div>
            <div>不能避免发生图像延迟、瞬间定格（影像一瞬间凝固、掉落框架等现象）的发生。</div>
            <div>也就是性能损失严重，实时性较低。</div>           
          </div>
          <div class="tiyan_ms">
            <div>我司的VividOS是针对嵌入式机器优化的，简单的，性能强化的OS。</div>
            <div>独自的Display Engine API具有高度的表现力，给用户提供没有卡顿，流畅的画面，提高了用户体验的舒适度。</div>
          </div>
        </div>
        <div class="OS_sol_sd">
          <div class="OS_sol_title">将各阶段的处理优化到极限，实现高速启动</div>
          <div class="OS_sol_title2">正在开发中的最新版本的vividOS，从开机到系统启动，画面显示，到游戏启动完成仅用0.8秒，实现了高速启动</div>
          <img src="@/assets/images/business/1/sol_os_04-1.jpg" alt="">
          <div class="OS_sol_sd_ms">在Linux启动时，需要5秒左右、Android需要10秒以上，而VividOS将多余的部分去掉，通过优化各阶段的处理，实现了1秒以内的高速启动。</div>
        </div>
        <div class="OS_sol_sx">
          <div class="OS_sol_title">实现GPU的加速（绘图功能高速化）</div>
          <img src="@/assets/images/business/1/sol_os_05-768x269.jpg" alt="">
          <p>VividOS实现了在自己的平台上的GPU加速。</p>
          <p>嵌入式设备可以实现前所未有的华丽光滑的动作、良好的响应性和舒适的用户体验。</p>
          <p>另外，尺寸紧凑，最适合嵌入式设备的VividOS，可以配合硬件调整OS，即使是低规格的处理器也能充分发挥其性能。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solutions",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
